<template>
    <div class="page">
        <div class="block-box" v-for="(mit, idx) in modules" :key="idx">
            <div class="head-box">
                <div class="title">{{ mit.title }}</div>
                <div class="date-filter">
                    <DatePicker type="date" :options="datePickerOptions" v-model="mit.date" placeholder="选择日期" style="width: 300px" @on-change="onChangeDate(mit)"></DatePicker>
                </div>
            </div>
            <div class="content-box">
                <div class="note-box">
                    <div class="item-box" v-for="(item, idx) in mit.modules" :key="idx">
                        <div class="title">{{ item.title }}</div>
                        <div class="time">{{ $core.formatDate(DateUtil.getDateByDay(1, mit.date), "yyyy-MM-dd 00:00") }}</div>
                        <div class="value-box">
                            <NumberRoll :number="item.value || 0"></NumberRoll>
                            <p class="unit">{{ item.unit }}</p>
                        </div>
                        <div class="trend-box">
                            <div class="trend-item">
                                <p class="name">较1日前</p>
                                <p class="value" :style="{ color: item.valueDayAgo >= 0 ? '#06a561' : '#F0142F' }">{{ !item.valueDayAgo || item.valueDayAgo >= 0 ? "+" : "" }}{{ item.valueDayAgo || 0 }}{{ item.unit }}</p>
                            </div>
                            <div class="trend-item">
                                <p class="name">较15日前</p>
                                <p class="value" :style="{ color: item.value15Ago >= 0 ? '#06a561' : '#F0142F' }">{{ !item.value15Ago || item.value15Ago >= 0 ? "+" : "" }}{{ item.value15Ago || 0 }}{{ item.unit }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-box">
                <div class="head-box">
                    <RadioGroup v-model="mit.onlineStatus" @on-change="getCommunityData(mit)">
                        <Radio label="">全部社区{{ mit.title }}</Radio>
                        <Radio label="1">试点社区{{ mit.title }}</Radio>
                        <Radio label="2">试用社区{{ mit.title }}</Radio>
                    </RadioGroup>
                    <div class="btn" @click="mit.type = mit.type === 'pie' ? 'bar' : 'pie'">
                        <Icon class="icon" type="md-swap" />
                        <span>{{ mit.type === "pie" ? "柱状图" : "饼状图" }}</span>
                    </div>
                </div>
                <div class="row-box">
                    <div class="table-box">
                        <Table :tableColumn="mit.tableColumn" :tableData="mit.dataList" />
                    </div>
                    <div class="chart-box">
                        <ElemChart :title="mit.title" :value="mit.value" :type="mit.type"></ElemChart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ElemChart from "./childrens/ElemChart.vue"
import NumberRoll from "./childrens/NumberRoll.vue"
import Table from "./childrens/Table.vue"
import DateUtil from "./utils/date"

import Street from "./utils/street"

export default {
    components: { ElemChart, NumberRoll, Table },

    data() {
        return {
            DateUtil: DateUtil,
            // 日期选择器选项
            datePickerOptions: {
                disabledDate: date => {
                    // 判断日期是否大于等于今天，如果是则禁止选择
                    return date && date.valueOf() >= new Date(this.$core.formatDate(new Date(), "yyyy-MM-dd 00:00:00")).valueOf()
                },
            },
            // 模块
            modules: [
                {
                    id: this.$core.randomString(),
                    title: "累计访问用户数据",
                    key: "statRegister",
                    day1: "registerNumDayAgo",
                    day15: "registerNum15Ago",
                    color: "#FC8452",
                    type: "pie",
                    limit: "1",
                    date: DateUtil.getDateByDay(-1),
                    modules: [
                        {
                            title: "全部社区累计访问用户",
                            unit: "人",
                        },
                        {
                            title: "试点社区累计访问用户",
                            unit: "人",
                            onlineStatus: "1",
                        },
                        {
                            title: "试用社区累计访问用户",
                            unit: "人",
                            onlineStatus: "2",
                        },
                    ],
                },
                {
                    id: this.$core.randomString(),
                    title: "新增访问用户数据",
                    key: "registerNum",
                    color: "#EE6666",
                    type: "bar",
                    limit: "1",
                    date: DateUtil.getDateByDay(-1),
                    modules: [
                        {
                            title: "全部社区新增访问用户",
                            unit: "人",
                        },
                        {
                            title: "试点社区新增访问用户",
                            unit: "人",
                            onlineStatus: "1",
                        },
                        {
                            title: "试用社区新增访问用户",
                            unit: "人",
                            onlineStatus: "2",
                        },
                    ],
                },
            ],
        }
    },

    async mounted() {
        let street = await Street.getInfo()
        this.orgPath = street.orgPath
        // 处理数据
        this.onProcessData()
    },

    methods: {
        /**
         * 处理数据
         */
        async onProcessData() {
            for (let i = 0, l = this.modules; i < l.length; i++) {
                let v = l[i]
                this.$set(v, "tableColumn", this.getTableColumn(v))
                await this.getCountDate(v)
                await this.getCommunityData(v)
            }
        },

        getCommunityData(item) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            var date = this.$core.formatDate(new Date(item.date), "yyyy-MM-dd")

            const selectedField = ["orgCode", "orgName", "shouldMember", "onlineStatus", "onlineType"]
            selectedField.push(item.key)

            if (item.key === "statRegister") {
                selectedField.push("proportion")
            }

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listCommunityValue",
                {
                    dataScopeId: this.orgPath, //范围编码
                    onlineStatus: item.onlineStatus,
                    statDate: date, //统计时间
                    selectedField: selectedField, //查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        const list = []

                        for (let i = 0, l = res.dataList; i < l.length; i++) {
                            let v = l[i]

                            list.push({
                                name: v.orgName,
                                value: v[item.key] || 0,
                            })
                        }

                        this.$set(item, "dataList", res.dataList)
                        this.$set(item, "value", list)
                    }
                })
                .finally(this.$Message.destroy)
        },

        getTableColumn(item) {
            const res = [
                {
                    title: "序号",
                    type: "index",
                    minWidth: 70,
                    align: "center",
                },
                {
                    title: "类型",
                    minWidth: 140,
                    align: "center",
                    render: (h, params) => {
                        if (params.row.onlineStatus === "1") {
                            return h("p", null, ({ 1: "市级", 2: "区级", 3: "街镇级" }[params.row.onlineType] || "") + "示范社区")
                        }
                        return h("p", null, { 2: "试用社区", 3: "非试用社区" }[params.row.onlineStatus] || "-")
                    },
                },
                {
                    title: "社区",
                    key: "orgName",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: item.title,
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row[item.key] || 0)
                    },
                },
            ]

            if (item.key === "statRegister") {
                res.push({
                    title: "在街道占比数",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("p", (params.row.proportion || "0.00") + "%")
                    },
                })
            }

            return res
        },

        /**
         * 获取统计数据
         */
        async getCountDate(item) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            var date = new Date(item.date)
            date.setDate(date.getDate() - 1)
            date = this.$core.formatDate(date, "yyyy-MM-dd")

            for (let i = 0, l = item.modules; i < l.length; i++) {
                let v = l[i]

                await this.$post(
                    "/gateway/api/ck/OrgIndicator/listValueCountByCode",
                    {
                        dataScopeId: this.orgPath,
                        statDate: date,
                        selectedField: [item.key, item.day1, item.day15, "onlineStatus"],
                        onlineStatus: v.onlineStatus,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        let data = res.data

                        let val = data[item.key] || 0
                        let valDay1 = data[item.day1] || 0
                        let valDay15 = data[item.day15] || 0

                        this.$set(v, "value", val)
                        this.$set(v, "value15Ago", val - valDay15)
                        this.$set(v, "valueDayAgo", val - valDay1)
                    }
                })
            }

            this.$Message.destroy()
        },

        /**
         * 监听日期变化
         */
        async onChangeDate(item) {
            await this.getCountDate(item)
            await this.getCommunityData(item)
        },
    },
}
</script>
<style lang="less" scoped>
.page {
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    .block-box {
        width: 100%;
        padding: 0 20px 10px 20px;
        box-sizing: border-box;
        max-width: 1500px;
        min-width: 700px;
        margin: 0 auto;

        &:first-child {
            padding-top: 20px;
        }

        > .head-box {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                line-height: 1;
                font-weight: bold;
                color: #333;
            }

            .date {
                display: flex;
                align-items: center;
                background: #fff;
                padding: 10px;
                border-radius: 6px;

                .condition-box {
                    margin-left: 20px;
                }
            }

            .date-filter {
                margin: 0 20px;
            }
        }

        .content-box {
            width: 100%;
            background: #fff;
            padding: 15px;
            margin: 20px 0;
            border-radius: 6px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);

            > .head-box {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .btn {
                    cursor: pointer;
                    padding: 6px 20px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    background: #f8f8f8;
                    color: #333;
                    border-radius: 4px;
                    flex-shrink: 0;
                    line-height: 1;
                    border: 1px solid #e3e3e3;

                    .icon {
                        margin-right: 10px;
                    }
                }
            }

            .note-box {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .item-box {
                    width: calc(100% / 3 - 10px);
                    margin: 10px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    box-sizing: border-box;
                    border-right: 1px solid #f3f3f3;

                    &:last-child {
                        border-right: 0;
                    }

                    .title {
                        color: #333;
                        font-size: 14px;
                    }

                    .time {
                        font-size: 12px;
                        color: #999;
                    }

                    > .value-box {
                        font-size: 50px;
                        line-height: 1;
                        display: flex;
                        align-items: flex-end;
                        margin: 10px 0;
                        color: #57a3f3;

                        .unit {
                            font-size: 14px;
                            line-height: 30px;
                            margin-left: 10px;
                        }
                    }

                    .trend-box {
                        display: flex;

                        .trend-item {
                            display: flex;
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .name {
                                font-size: 12px;
                                color: #444;
                                margin-right: 10px;
                            }

                            .value {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .row-box {
                width: 100%;
                display: flex;
                height: 350px;

                .table-box {
                    flex-grow: 1;
                    width: 40%;
                }

                .chart-box {
                    flex-shrink: 0;
                    margin-left: 20px;
                    width: calc(60% - 20px);
                }
            }

            .chart-module {
                width: 100%;

                .head-box {
                    margin: 10px 10px 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 1;

                    > .title {
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    .date {
                        flex-grow: 1;
                        color: #999;
                        display: flex;
                        align-items: center;

                        .condition-box {
                            margin-left: 20px;
                        }
                    }

                    .download-btn {
                        cursor: pointer;
                        padding: 8px 20px;
                        display: flex;
                        align-items: center;
                        background: #e3e3e3;
                        color: #000;
                        border-radius: 4px;
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }

                        .name {
                            margin-left: 5px;
                        }
                    }

                    .more {
                        cursor: pointer;
                        font-weight: bold;
                    }
                }

                .type-box {
                    margin: 10px 10px 0 10px;
                }

                .chart-box {
                    margin-top: 20px;
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }
}
</style>
